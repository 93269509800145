.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-link {
  color: #09d3ac;
}

body {
  background-color: #edeff1;
}
