body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  padding-top: 25px;
  padding-bottom: 200px;
}

.status-box {
  position: fixed;
  left: 10px;
  top: 30px;
  height: 200px;
  width: 300px;
  background-color: rgba(0,0,0, 0.4);
  text-align: left;
  color: white;
  font-size: 10px;
  padding: 5px;
  border-radius: 10px;
  z-index: 999;
  overflow-y: auto;
}

.game-card {
  border: thin solid #b6b6b6;
  padding: 25px;
  border-radius: 15px;
  background-color: #fff;
}
